import { Injectable } from '@angular/core';
import { ChartOptions } from '../chart-options';

@Injectable({
	providedIn: 'root'
})
export class ChartService {

	constructor() {

	}

	public salesOverview(): Partial<ChartOptions>
    {
        return {
            series: [{
                name: 'Sales',
                data: [4, 8, 6, 9, 6, 7, 4, 5, 2.5, 3]
            }],
            chart: {
                type: 'area',
                foreColor: '#9a9797',
                height: 300,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                dropShadow: {
                    enabled: false,
                    top: 3,
                    left: 14,
                    blur: 4,
                    opacity: 0.12,
                    color: '#299ca9',
                },
                sparkline: {
                    enabled: false
                },

            },
            markers: {
                size: 0,
                colors: ['#299ca9'],
                strokeColors: '#fff',
                strokeWidth: 2,
                hover: {
                    size: 7,
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '45%',
                },
            },
            dataLabels: {
                enabled: false,
                distributed: true
            },
            stroke: {
                show: true,
                width: 3,
                curve: 'smooth'
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'light',
                    type: 'vertical',
                    shadeIntensity: 0.5,
                    gradientToColors: ['#fff'],
                    inverseColors: false,
                    opacityFrom: 0.8,
                    opacityTo: 0.5,
                    stops: [0, 100]
                }
            },
            colors: ['#299ca9'],
            grid: {
                show: true,
                borderColor: '#ededed',
                // strokeDashArray: 4,
            },
            yaxis: {
                labels: {
                    formatter(value)
                    {
                        return value + '';
                    }
                },
            },
            xaxis: {
                // labels: {
                // 	show: true,
                // 	rotate: 0,
                // 	hideOverlappingLabels: true,
                // },
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
                tickAmount: 6
            },
            tooltip: {
                theme: 'dark',
                y: {
                    formatter(val)
                    {
                        return '' + val;
                    }
                }
            }
        };
    }

    public vehiclePurchasedOptions(): Partial<ChartOptions>
    {
        return {
            series: [
                {
                    data: [10, 41, 35, 51, 49, 62, 69]
                }
            ],
            chart: {
                height: 60,
                type: 'line',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                dropShadow: {
                    enabled: false,
                    top: 3,
                    left: 14,
                    blur: 4,
                    opacity: 0.12,
                    color: '#299ca9',
                },
                sparkline: {
                    enabled: true
                }
            },
            markers: {
                size: 0,
                colors: ['#299ca9'],
                strokeColors: '#fff',
                strokeWidth: 2,
                hover: {
                    size: 7,
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '45%',
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2.5,
                curve: 'smooth'
            },
            colors: ['#299ca9'],
            tooltip: {
                theme: 'dark',
                fixed: {
                    enabled: false
                },
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter(seriesName)
                        {
                            return '';
                        }
                    }
                },
                marker: {
                    show: false
                }
            },
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep']
            },
            yaxis: {
                labels: {
                    formatter(value)
                    {
                        return value + '';
                    }
                },
            }
        };
    }

    public DealOptions(): Partial<ChartOptions>
    {
        return {
            series: [{
                name: 'Deals In Progress',
                data: [414, 555, 257, 901, 613, 727, 414, 555, 257]
            }],
            chart: {
                type: 'bar',
                height: 60,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                dropShadow: {
                    enabled: false,
                    top: 3,
                    left: 14,
                    blur: 4,
                    opacity: 0.12,
                    color: '#f41127',
                },
                sparkline: {
                    enabled: true
                }
            },
            markers: {
                size: 0,
                colors: ['#f41127'],
                strokeColors: '#fff',
                strokeWidth: 2,
                hover: {
                    size: 7,
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '40%',
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2.5,
                curve: 'smooth'
            },
            colors: ['#f41127'],
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            },
            yaxis: {
                labels: {
                    formatter(value)
                    {
                        return value + '';
                    }
                },
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                theme: 'dark',
                fixed: {
                    enabled: false
                },
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter(seriesName)
                        {
                            return '';
                        }
                    }
                },
                marker: {
                    show: false
                }
            }
        };
    }

    public VehiclesSoldOptions(): Partial<ChartOptions>
    {
        return {
            series: [{
                name: 'Vehicles Sold',
                data: [414, 555, 257, 901, 613, 727, 414, 555, 257]
            }],
            chart: {
                type: 'area',
                height: 60,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                dropShadow: {
                    enabled: false,
                    top: 3,
                    left: 14,
                    blur: 4,
                    opacity: 0.12,
                    color: '#ffc107',
                },
                sparkline: {
                    enabled: true
                }
            },
            markers: {
                size: 0,
                colors: ['#ffc107'],
                strokeColors: '#fff',
                strokeWidth: 2,
                hover: {
                    size: 7,
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '45%',
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2.5,
                curve: 'smooth'
            },
            colors: ['#ffc107'],
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            },
            yaxis: {
                labels: {
                    formatter(value)
                    {
                        return value + '';
                    }
                },
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                theme: 'dark',
                fixed: {
                    enabled: false
                },
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter(seriesName)
                        {
                            return '';
                        }
                    }
                },
                marker: {
                    show: false
                }
            }
        };
    }

    public sorStockOptions(): Partial<ChartOptions>
    {
        return {
            series: [{
                name: 'Bounce Rate',
                data: [414, 555, 257, 901, 613, 727, 414, 555, 257]
            }],
            chart: {
                type: 'bar',
                height: 60,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                dropShadow: {
                    enabled: false,
                    top: 3,
                    left: 14,
                    blur: 4,
                    opacity: 0.12,
                    color: '#0dcaf0',
                },
                sparkline: {
                    enabled: true
                }
            },
            markers: {
                size: 0,
                colors: ['#0dcaf0'],
                strokeColors: '#fff',
                strokeWidth: 2,
                hover: {
                    size: 7,
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '40%',
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2.4,
                curve: 'smooth'
            },
            colors: ['#0dcaf0'],
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                theme: 'dark',
                fixed: {
                    enabled: false
                },
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter(seriesName)
                        {
                            return '';
                        }
                    }
                },
                marker: {
                    show: false
                }
            }
        };
    }

    public bookingOptions(): Partial<ChartOptions>
    {
        return {
            series: [{
                name: 'Avg. Session Duration',
                data: [414, 555, 257, 901, 613, 727, 414, 555, 257]
            }],
            chart: {
                type: 'line',
                height: 60,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
                dropShadow: {
                    enabled: false,
                    top: 3,
                    left: 14,
                    blur: 4,
                    opacity: 0.12,
                    color: '#29cc39',
                },
                sparkline: {
                    enabled: true
                }
            },
            markers: {
                size: 0,
                colors: ['#29cc39'],
                strokeColors: '#fff',
                strokeWidth: 2,
                hover: {
                    size: 7,
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '45%',
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2.5,
                curve: 'smooth'
            },
            colors: ['#29cc39'],
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                theme: 'dark',
                fixed: {
                    enabled: false
                },
                x: {
                    show: false
                },
                y: {
                    title: {
                        formatter(seriesName)
                        {
                            return '';
                        }
                    }
                },
                marker: {
                    show: false
                }
            }
        };
    }

    public salesStaffReport(): Partial<ChartOptions>
    {
        return {
            series: [{
                name: 'Staff Sales',
                data: [44, 55, 13, 43, 22]
            }],
            chart: {
                foreColor: '#9ba7b2',
                width: '100%',
                height: 'auto',
                type: 'pie',
            },
            colors: ['#299ca9', '#6c757d', '#17a00e', '#f41127', '#ffc107'],
            xaxis: {
                categories: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
            },
            plotOptions: {
                pie: {
                    startAngle: 0,
                    endAngle: 360,
                    customScale: 1
                }
            }
        };
    }

    public purchaseStaffReport(): Partial<ChartOptions>
    {
        return {
            series: [{
                name: 'Purchase Sales',
                data: [44, 55, 13, 43, 22]
            }],
            chart: {
                foreColor: '#9ba7b2',
                width: '100%',
                height: 'auto',
                type: 'pie',
            },
            colors: ['#299ca9', '#6c757d', '#17a00e', '#f41127', '#ffc107'],
            xaxis: {
                categories: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
            },
            plotOptions: {
                pie: {
                    startAngle: 0,
                    endAngle: 360,
                    customScale: 1
                }
            }
        };
    }

}
