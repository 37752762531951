<app-admin-header></app-admin-header>

<div class="page-wrapper">
    <div class="page-content">
        @if (!authService.accountIntegration?.active) {
            <div class="alert alert-danger border-0 bg-danger fade show py-2">
                <div class="d-flex align-items-center">
                    <div class="font-35 text-white">
                        <i class="fa-solid fa-circle-xmark"></i>
                    </div>

                    <div class="ms-3 w-100">
                        <h6 class="mb-0 text-white">Unconnected Account Service!</h6>
                        <div class="text-white">We couldn't find an active accounts connection. To continue to use the system, please connect your account system.</div>

                        <hr />

                        <a routerLink="/app/settings" fragment="connections" class="text-white fw-bold text-decoration-underline">Connect Accounts</a>
                        <a href="#" class="text-white fw-bold text-decoration-underline float-end">Help</a>
                    </div>
                </div>
            </div>
        }

        <router-outlet></router-outlet>
    </div>
</div>

<div class="overlay toggle-icon"></div>

<app-admin-footer></app-admin-footer>
