import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Lead } from '../leads/models/lead.model';
import { ChartOptions } from '../../shared/chart-options';
import { ChartService } from '../../shared/services/chart.service';
import { ApiService } from '../../shared/services/api.service';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {

	public vehiclePurchasedOptions: Partial<ChartOptions>;
	public enquiriesOptions: Partial<ChartOptions>;
	public vehiclesSoldOptions: Partial<ChartOptions>;
	public sorStockOptions: Partial<ChartOptions>;
	public bookingsOptions: Partial<ChartOptions>;
	public saleOverviewOptions: Partial<ChartOptions>;

	public purchaseOverviewOptions: Partial<ChartOptions>;

	//Leads Data & Variables
	displayedColumns: string[] = ['leadId', 'vehicleId', 'name', 'statusId'];
	dataSource: MatTableDataSource<Lead> = new MatTableDataSource();
	// selection = new SelectionModel<Lead>(true, []);
	@ViewChild(MatPaginator) paginator: MatPaginator;

	pageSize: number = 12;
	length: number = 0;
	pageIndex: number = 0;
	pageEvent: PageEvent;
	currentPage: number = 0;

	//Dashboard Results
	VehiclesInStock: [] = [];
	Enquiries: [] = [];
	VehiclesSold: [] = [];
	SORStock: [] = [];
	Bookings: [] = [];

	//Test Graph
	single: any[];
	multi: any[];
	view: any[] = [700, 400];

	// options
	showXAxis = true;
	showYAxis = true;
	gradient = false;
	showLegend = true;
	showXAxisLabel = true;
	xAxisLabel = 'Days';
	showYAxisLabel = true;
	yAxisLabel = 'Cars Sold';
	showSalesOverview: boolean = false;
	type: string = '30days';

	vehiclePurchaseChart: UntypedFormGroup = new UntypedFormGroup({
		reportSelect: new UntypedFormControl(this.type, [Validators.required]),
	});

	vehicleSaleChart: UntypedFormGroup = new UntypedFormGroup({
		reportSelect: new UntypedFormControl(this.type, [Validators.required]),
	});

	colorScheme = {
		domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
	};

	constructor(
		public apiService: ApiService,
		private chartService: ChartService) {
		this.single = [];
		this.vehiclePurchasedOptions = this.chartService.vehiclePurchasedOptions();
		this.purchaseOverviewOptions = this.chartService.salesOverview();
		this.saleOverviewOptions = this.chartService.salesOverview();
		this.enquiriesOptions = this.chartService.DealOptions();
		this.vehiclesSoldOptions = this.chartService.VehiclesSoldOptions();
		this.sorStockOptions = this.chartService.sorStockOptions();
		this.bookingsOptions = this.chartService.bookingOptions();

		this.vehicleSaleChart.valueChanges.subscribe(() => {
			this.getSaleOverview();
		});

		this.vehiclePurchaseChart.valueChanges.subscribe(() => {
			this.getPurchaseOverview();
		});

		this.apiService.get('dashboard/primary-graphs').subscribe((data: any) => {
			this.VehiclesInStock = data.vehicles_in_stock;
			this.vehiclePurchasedOptions.series = [{
				data: data.vehicles_in_stock
			}];

			this.Enquiries = data.enquiries;
			this.enquiriesOptions.series = [{
				data: data.enquiries
			}];

			this.VehiclesSold = data.vehicles_sold;
			this.vehiclesSoldOptions.series = [{
				data: data.vehicles_sold
			}];

			this.SORStock = data.sale_or_return_stock;
			this.sorStockOptions.series = [{
				data: data.sale_or_return_stock
			}];

			this.Bookings = data.bookings;
			this.bookingsOptions.series = [{
				data: data.bookings
			}];
		});

		this.getSaleOverview();
		this.getPurchaseOverview();
	}

	calculateAverage(array: []) {
		const cloneArray = [...array];
		var lastVal = cloneArray.pop() ?? 0;
		const sum = cloneArray.reduce((a, b) => a + b, 0);
		const avg = (sum / cloneArray.length) || 0;

		var value = Number(((avg / lastVal) * 100).toFixed(1).replace(/[.,]0+$/, ''));
		const positive = avg >= lastVal ? false : true;
		// debugger;
		value = avg == lastVal ? 0 : value;
		if (lastVal > 0) {
			return {
				percentage: value,
				positive: positive
			};
		} else {
			return {
				percentage: Number((avg * 100).toFixed(1).replace(/[.,]0+$/, '')),
				positive: false
			};
		}
	}

	getSaleOverview() {
		this.apiService.get(`dashboard/vehicle-overview`, {params: {
			period: this.vehicleSaleChart.get('reportSelect')?.value,
			type: 'sale'
		}}).subscribe((data: any) => {
			// debugger;
			this.saleOverviewOptions.series = [{
				name: 'Sale',
                data: data
            }];
		});
	}

	getPurchaseOverview(){
		this.apiService.get(`dashboard/vehicle-overview`, {params: {
			period: this.vehiclePurchaseChart.get('reportSelect')?.value,
			type: 'purchase'
		}}).subscribe((data: any) => {
			// debugger;
			this.purchaseOverviewOptions.series = [{
				name: 'Purchases',
                data: data
            }];
		});
	}

}
