import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AdminComponent} from './admin.component';
import {RouterModule, RouterOutlet} from "@angular/router";
import {AdminFooterComponent} from "../core/components/admin-footer/admin-footer.component";
import {AdminHeaderComponent} from "../core/components/admin-header/admin-header.component";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import {AdminRoutingModule} from "./admin-routing.module";
import { FormMapService } from '../shared/services/form-map.service';
import { FilterService } from "../shared/services/filter.service";
import { NgSelectModule } from "@ng-select/ng-select";
import { provideNgxMask } from 'ngx-mask';
import { SharedModule } from '../shared/shared.module';
import { NgApexchartsModule } from 'ng-apexcharts';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        AdminRoutingModule,
        RouterOutlet,
        NgSelectModule,
        NgbDropdownModule,
        NgApexchartsModule
    ],
    exports: [
        AdminFooterComponent,
        AdminHeaderComponent,
        SharedModule
    ],
    declarations: [
        DashboardComponent,
        AdminComponent,
        AdminFooterComponent,
        AdminHeaderComponent,
    ],
    providers: [
        FormMapService,
        FilterService,
        DatePipe,
        provideNgxMask()
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AdminModule {
}
