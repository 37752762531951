import {Component} from '@angular/core';
import {NgbActiveModal, NgbModalModule} from "@ng-bootstrap/ng-bootstrap";
import {AuthService} from "../../../../core/services/auth.service";
import {IntegrationTypes} from "../../../../models/enums/integration-types.enum";
import {ApiService} from "../../../services/api.service";
import {environment} from "../../../../../environments/environment";
import {HttpErrorResponse} from "@angular/common/http";
import {CommonModule} from "@angular/common";

@Component({
    selector: 'app-account-modal',
    templateUrl: './account-modal.component.html',
    styleUrl: './account-modal.component.scss',
    standalone: true,
    imports: [
        CommonModule,
        NgbModalModule
    ]
})
export class AccountModalComponent {
    protected readonly IntegrationTypes = IntegrationTypes;

    public constructor(
        public activeModal: NgbActiveModal,
        public authService: AuthService,
        private apiService: ApiService,
    ) {
    }

    public connect(id: IntegrationTypes): void {
        this.apiService.post(`integrations/connect/${id}`, {frontend_url: `${environment.localPath}/app/settings`}).subscribe({
            next: (response) => window.location.href = response.redirect_url,
            error: (error: HttpErrorResponse) => this.apiService.handleError(error.message)
        });
    }
}
