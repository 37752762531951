import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment} from '@angular/router';
import {AuthService} from "../services/auth.service";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {AccountModalComponent} from "../../shared/components/modals/account-modal/account-modal.component";
import {ApiService} from "../../shared/services/api.service";

@Injectable({
    providedIn: 'root'
})
export class AccountIntegrationGuard {
    constructor(
        private authService: AuthService,
        private apiService: ApiService,
        private modalService: NgbModal,
    ) {
    }

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        return this.check(state.url);
    }

    public canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        return this.check(state.url);
    }

    public canLoad(
        route: Route,
        segments: UrlSegment[]
    ): boolean {
        return this.check('/');
    }

    private check(redirectUrl: string): boolean {
        this.apiService.get(`tenants/${this.authService.tenant.id}`).subscribe({
            next: (response) => {
                this.authService.tenant = response.data;

                const hasActiveAccount: boolean = this.authService.tenantHasActiveAccount();

                if (!hasActiveAccount) {
                    const modalRef: NgbModalRef = this.modalService.open(AccountModalComponent, {
                        size: 'lg',
                        centered: true,
                        fullscreen: false,
                        keyboard: false,
                        beforeDismiss: () => {
                            return false;
                        }
                    });

                    modalRef.result.then(result => {
                        console.log(result);
                    });
                }

                return true;
            },
            error: (response) => this.apiService.handleError(response)
        });

        return true;
    }
}
