<div class="sidebar-wrapper">
    <div class="sidebar-header">
<!--        <div *ngIf="company.companyLogo?.length > 14">-->
<!--            <img [src]="company.companyLogo" alt="logo icon" class="full-logo-icon" />-->
<!--        </div>-->
<!---->
<!--        <div *ngIf="company.companyLogo?.length < 14">-->
        <div>
            <h4 class="logo-text">{{ authService.tenant.name }}</h4>
        </div>

        <div class="toggle-icon ms-auto" (click)="toggleSidebarClass()">
            <i class='fa fa-bars'></i>
        </div>
<!--        </div>-->
    </div>

    <ul class="metismenu mm-show" id="menu">
        @for (menuItem of menuItems; track menuItem; let  i = $index) {
            <li [routerLinkActive]="'mm-active'" [routerLinkActiveOptions]="{queryParams: 'ignored', matrixParams: 'exact', paths: 'subset', fragment: 'exact' }">
                <a
                    *ngIf="!menuItem.isExternalLink; else externalLinkBlock"
                    [routerLink]="!menuItem.submenu ? menuItem.path : null"
                    [queryParams]="!menuItem.submenu ? menuItem.queryParams : null"
                    [fragment]="!menuItem.submenu ? menuItem.fragment ?? null : null"
                    (click)="expandSubMenu(menuItem, i)"
                    [attr.aria-expanded]="menuItem.submenu && menuItem.expanded"
                    [ngClass]="menuItem.submenu ? 'has-arrow' : null"
                >
                    <div class="parent-icon"><i class="fa-regular fa-fw fa-sm {{ menuItem.icon }}"></i></div>
                    <div class="menu-title">{{ menuItem.title }}</div>
                    <span *ngIf="menuItem.badgeClass != ''" class="ms-auto {{ menuItem.badgeClass }}">{{ menuItem.badge }}</span>
                </a>

                <ng-template #externalLinkBlock>
                    <a [href]="[menuItem.path]" target="_blank">
                        <div class="parent-icon"><i class="fa-regular fa-fw fa-sm {{ menuItem.icon }}"></i></div>
                        <span class="menu-title">{{ menuItem.title }}</span>
                        <span *ngIf="menuItem.badgeClass != ''" class="ms-auto {{ menuItem.badgeClass }}">{{ menuItem.badge }}</span>
                    </a>
                </ng-template>

                <ul *ngIf="menuItem.submenu" [ngClass]="menuItem.expanded ? 'mm-show' : null" class="mm-collapse" [routerLinkActive]="'mm-show'">
                    @for (subItem of menuItem.submenu; track subItem; let  si = $index) {
                        <li [routerLinkActive]="'mm-active'">
                            <a
                                *ngIf="!subItem.isExternalLink; else externalSubLinkBlock"
                                [routerLink]="!subItem.submenu ? subItem.path : null"
                                [queryParams]="!subItem.submenu ? subItem.queryParams : null"
                                [fragment]="!subItem.submenu ? subItem.fragment ?? null : null"
                                (click)="expandSubMenu(subItem, si)"
                                [attr.aria-expanded]="subItem.submenu && subItem.expanded"
                                [ngClass]="subItem.submenu ? 'has-arrow' : null"
                            >
                                <div class="parent-icon"><i class="fa-light fa-fw fa-2xs {{ subItem.icon }}"></i></div>
                                <span class="sub-menu-title">{{ subItem.title }}</span>
                                <span *ngIf="subItem.badgeClass != ''" class="ms-auto {{ subItem.badgeClass }}">{{ subItem.badge }}</span>
                            </a>

                            <ng-template #externalSubLinkBlock>
                                <a [href]="[subItem.path]" target="_blank">
                                    <div class="parent-icon"><i class="fa-regular fa-fw fa-sm {{ subItem.icon }}"></i></div>
                                    <span class="menu-title">{{ subItem.title }}</span>
                                    <span *ngIf="subItem.badgeClass != ''" class="ms-auto {{ subItem.badgeClass }}">{{ subItem.badge }}</span>
                                </a>
                            </ng-template>

                            <ul *ngIf="subItem.submenu" [ngClass]="subItem.expanded ? 'mm-show' : null" class="mm-collapse">
                                @for (subSubItem of subItem.submenu; track subSubItem; let ssi = $index) {
                                    <li>
                                        <a
                                            *ngIf="!subSubItem.isExternalLink; else externalSubLinkBlock"
                                            [routerLink]="!subSubItem.submenu ? subSubItem.path : null"
                                            [queryParams]="!subSubItem.submenu ? subSubItem.queryParams : null"
                                            [fragment]="!subSubItem.submenu ? subSubItem.fragment ?? null : null"
                                            (click)="expandSubMenu(subSubItem, ssi)"
                                            [ngClass]="subSubItem.submenu ? 'has-arrow' : null"
                                        >
                                            <div class="parent-icon"><i class="fa-light fa-fw fa-2xs {{ subSubItem.icon }}"></i></div>
                                            <span class="sub-menu-title">{{ subSubItem.title }}</span>
                                            <span *ngIf="subSubItem.badgeClass != ''" class="ms-auto {{ subSubItem.badgeClass }}">{{ subSubItem.badge }}</span>
                                        </a>

                                        <ng-template #externalSubLinkBlock>
                                            <a [href]="[subSubItem.path]" target="_blank">
                                                <div class="parent-icon"><i class="fa-regular fa-fw fa-sm {{ subSubItem.icon }}"></i></div>
                                                <span class="menu-title">{{ subSubItem.title }}</span>
                                                <span *ngIf="subSubItem.badgeClass != ''" class="ms-auto {{ subSubItem.badgeClass }}">{{ subSubItem.badge }}</span>
                                            </a>
                                        </ng-template>
                                    </li>
                                }
                            </ul>
                        </li>
                    }
                </ul>
            </li>
        }
    </ul>
</div>

<header>
    <div class="topbar d-flex align-items-center bg-primary shadow-none border-light-2 border-bottom">
        <nav class="navbar navbar-expand">
            <div class="toggle-icon text-white me-3" (click)="toggleSidebarClass()"><i class="fa-solid fa-bars"></i></div>

            <div class="search-bar flex-grow-1">
                <div class="position-relative search-bar-box">
                    <form>
                        <input autofocus class="form-control search-control" placeholder="Type to search..." type="text">
                        <span class="position-absolute top-50 search-show translate-middle-y"><i class="bx bx-search"></i></span>
                        <span class="position-absolute top-50 search-close translate-middle-y"><i class="bx bx-x"></i></span>
                    </form>
                </div>
            </div>

            <div class="top-menu ms-auto">
                <ul class="navbar-nav align-items-center">
                    <li class="nav-item">
<!--                        <ng-container *ngIf="!preferencesService.privacyMode">-->
<!--                            <a (click)="togglePrivacyMode(true)" class="nav-link cursor-pointer" matTooltip="Enable Privacy Mode"><i class="fa-solid fa-eye"></i></a>-->
<!--                        </ng-container>-->

<!--                        <ng-container *ngIf="preferencesService.privacyMode">-->
<!--                            <a (click)="togglePrivacyMode(false)" class="nav-link cursor-pointer" matTooltip="Disable Privacy Mode"><i class="fa-solid fa-eye-slash"></i></a>-->
<!--                        </ng-container>-->
                    </li>

                    <!--TODO: Re Enable notifications -->
                    <!-- <li ngbDropdown class="nav-item dropdown dropdown-large">
                        <a class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative text-white"
                        type="button"
                        ngbDropdownToggle aria-expanded="false" id="notificationsDropdown">
                        <span class="alert-count" *ngIf="unreadNotif != 0">{{unreadNotif}}</span>
                            <i class='bx bx-bell'></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu aria-labelledby="notificationsDropdown">
                            <a href="javascript:;">
                                <div class="msg-header">
                                    <p class="msg-header-title">Notifications</p>
                                    <p class="msg-header-clear ms-auto">Marks all as read</p>
                                </div>
                            </a>

                            <div class="header-notifications-list">
                                <a class="dropdown-item" *ngFor="let notification of sortData ">
                                    <div class="d-flex align-items-center">
                                        <div class="notify bg-light-primary text-primary"><i class="bx bx-group"></i>
                                        </div>
                                        <div class="flex-grow-1">
                                            <h6 class="msg-name">{{notification.title}}<span class="msg-time float-end">{{moment(notification.createdAt).fromNow(true)}} ago</span></h6>
                                            <p class="msg-info">{{notification.message}}</p>
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <a href="javascript:;">
                                <div class="text-center msg-footer">Load More Notifications</div>
                            </a>
                        </div>
                    </li> -->

                </ul>
            </div>

            <div class="user-box dropdown border-light-2" ngbDropdown>
                <a aria-expanded="false" class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret" id="dropdownMenuButton2" ngbDropdownToggle type="button">
                    <div class="user-circle me-0">
                        <p class="user-circle-inner">{{ authService.initials }}</p>
                    </div>
                    <div class="user-info ps-3">
                        <p class="user-name mb-0 text-white">{{ authService.user.name }}</p>
                        <p class="designattion mb-0">{{ authService.tenant.name }}</p>
                    </div>
                </a>
                <ul aria-labelledby="dropdownMenuButton2" class="dropdown-menu" ngbDropdownMenu>
                    <li>
                        <a [routerLink]="'/app/profile'" class="dropdown-item"><i class="fa-solid fa-fw me-2 fa-user"></i> <span>Profile</span></a>
                    </li>

                    <li>
                        <div class="dropdown-divider mb-0"></div>
                    </li>

                    <li>
                        <a (click)="authService.logout()" class="dropdown-item"><i class="fa-solid fa-fw me-2 fa-right-from-bracket"></i> <span>Logout</span></a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</header>
