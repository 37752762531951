import {Component} from '@angular/core';
import {AuthService} from "../core/services/auth.service";

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrl: './admin.component.scss'
})
export class AdminComponent {
    constructor(
        public authService: AuthService,
    ) {
    }
}
