import {Component} from '@angular/core';
import packageInfo from '../../../../../package.json';

@Component({
    selector: 'app-admin-footer',
    templateUrl: './admin-footer.component.html',
    styleUrl: './admin-footer.component.scss'
})
export class AdminFooterComponent {
    public version: string;
    public year: number;

    public constructor() {
        this.version = packageInfo.version;
        this.year = new Date().getFullYear();
    }
}
